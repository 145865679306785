
export class AUDIO_RECORDER {
  audio_chunks = []
  stream = null
  mediaRecorder = null
  device_id = ''
  time = 0
  state = ''
  options = null
  onchunksready=null
  audio_recording_interval_id=null
  constructor(props){
    if(props.device_id) this.device_id=props.device_id
    if(props.options) this.options=props.options
  }
  async makeStream(){
    this.stream = await navigator.mediaDevices.getUserMedia({ 
      audio: {
        deviceId: this.device_id ? this.device_id : 'default',
      },
    })
  }
  async removeStream(){
    this.stream?.getTracks?.().forEach((track)=>track.stop())
    this.stream=null
  }
  async start(){
    if(!this.stream) return;
    this.mediaRecorder = new MediaRecorder(this.stream);
    this.mediaRecorder.ondataavailable = (event) => {
      this.audio_chunks?.push?.(event.data);
    }
    this.mediaRecorder.onstart = () => {
      this.startTimer()
    }
    this.mediaRecorder.onpause = () => {
      this.stopTimer()
      if(this.audio_chunks) this.onchunksready?.({ chunks: [...this.audio_chunks] })
      this.audio_chunks=[]
    }
    this.mediaRecorder.onresume = () => {
      this.startTimer()
    }
    this.mediaRecorder.onstop = () => {
      this.stopTimer()
      if(this.audio_chunks) this.onchunksready?.({ chunks: [...this.audio_chunks] })
      this.audio_chunks=[]
    };
    this.mediaRecorder.start(0.5*1000)
    this.state='record'
  }
  pause(){
    if (this.mediaRecorder.state=='recording') {
      this.mediaRecorder.pause();
      this.state='pause'
    }
  }
  resume(){
    if (this.mediaRecorder.state=='paused') {
      this.mediaRecorder.resume();
      this.state='record'
    }
  }
  stop(){
    this.mediaRecorder?.stop?.();
    this.state=''
    this.time=0
  }
  reset(){
    this.audio_chunks=null
    this.stop()
    this.removeStream()
  }
  startTimer(){
    this.audio_recording_interval_id = setInterval(() => {
      this.time = this.time + 1;
    }, 1 * 1000);
  }
  stopTimer(){
    clearInterval(this.audio_recording_interval_id)
  }
}